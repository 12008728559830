import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import styled, { css } from 'styled-components'
import SignupHero from '../components/heros/signup-hero'
import Layout from '../components/layout'
import Navigation from '../components/navigation'
import FeatureIconCard from '../components/cards/icon-feature-card'
import CheckListItem from '../components/atoms/check-list-item'
import EmailCtaSection from '../components/email-signup/email-cta-section'

class SignupIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const tips = get(this, 'props.data.allContentfulTip.edges')

    return (
      <Layout location={this.props.location} >
        <Helmet title={siteTitle} />
        <Navigation />
        <SignupHero 
          heroHeading="So you’re interested in subscribing?" 
          heroParagraph="If you're wanting regular content, that will help you become a better designer, use the subscribe form below." 
          heroImage="" 
        ></SignupHero>

        <div className="section-padding-bottom">
          <EmailCtaSection />
        </div>

        <div className="container boxed-width paddding-bottom-large">
          <FeatureIconCard icon="coffee" title="It’s free" description="This is completely free, no ads, what more could you want."/>
          <FeatureIconCard icon="handshake" title="It’s helpful" description="This should all help you (and me) be better designers."/>
          <FeatureIconCard icon="envelope" title="More to come" description="The content here will keep growing and give you an arsenal of information."/>
          <FeatureIconCard icon="hand-peace" title="No spam" description="There will never be any spam from this site. Just good content."/>
        </div>

        <div className="container boxed-width">
          <div className="col-7 col-12-t">
            <YouTubeVideo width="670" height="388" src="https://www.youtube.com/embed/CC0rvJYQy_4" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></YouTubeVideo>
          </div>

          <div className="container col-4 col-12-t align-center">
            <h2> Is it just tips? </h2>
            <p>No! I’m dedicated to bringing more than just tips. This will include design reviews, video tutorials, design resource lists, and everything I can conjure to make us all better designers (myself included). </p>
            <FeatureList>
              <CheckListItem listItem="Design reviews" />
              <CheckListItem listItem="Resource lists" />
              <CheckListItem listItem="Video tutorials" />
              <CheckListItem listItem="Much more" />
            </FeatureList>
          </div>
        </div>

      </Layout>
    )
  }
}

export default SignupIndex

export const signupQuery = graphql`
  query SignupQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`

// Styles

const BlogList = styled.div`
  list-style: none;
`

const HeroEmojiSpan = styled.span`
  padding: 0 0px;
`

const SectionHeadline = styled.h2 `
  margin-bottom: 48px;
`

const TipCount = styled.div `
  color: #7A89FF;
  display: inline-block;
`

const YouTubeVideo = styled.iframe `
  border-radius: 8px;
  width: 100%;

  @media (max-width: 768px) {
    margin-bottom: 24px;
  }
`

const FeatureList = styled.ul `
  display: flex;
  flex-wrap: wrap;
`